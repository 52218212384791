<script>
import { mapState } from "vuex";
import CurrencyInput from "./CurrencyInput.vue";
import { mapActions, mapMutations } from "vuex/dist/vuex.common.js";
import { PAYMENT_REQUEST_ITEM_NAMES } from "../data/payment.data";
import { webAppURL } from "@/constants";
import _debounce from "lodash/debounce";
import { userStorage } from "@/services/userStorage";

export default {
  name: "PosPaymentForm",
  components: {
    CurrencyInput
  },
  data() {
    return {
      locations: [],
      paymentRequestId: null,
      paymentErrorText: null,
      convenienceFeeAmount: null,
      // loading states
      isLoadingLocations: false,
      isLoadingPaymentRequest: false,
      isLoadingClient: false,
      isTakingPayment: false,
      isFetchingConvenienceFee: false,
      // form values
      terminalId: null,
      currentBalance: null,
      coPay: null,
      preServiceDeposit: null,
      coPayLocationId: null,
      preServiceDepositLocationId: null,
      signPatientForAutoPay: false,
      nonCCConvenienceFeeChargingIsDisabled: false,
      workflowSettings: [],
      isCheckboxSignPatientForAutoPayForPOSVisible: true,
    };
  },
  watch: {
    terminalId() {
      const terminal = this.terminals.find(i => i.id === this.terminalId);

      if (!terminal) return;

      const defaultLocationId = terminal.default_locations_breakdown
        ? terminal.default_locations_breakdown[0]
        : null;

      this.coPayLocationId = defaultLocationId;
      this.preServiceDepositLocationId = defaultLocationId;
    },
    totalAmount: _debounce(function(amount) {
      this.isFetchingConvenienceFee = true;

      this.getConvenienceFee(amount)
        .then(fee => {
          this.convenienceFeeAmount =
            fee && fee.convenience_fee_amount_non_ach
              ? parseFloat(fee.convenience_fee_amount_non_ach)
              : null;
        })
        .catch(() => {
          this.convenienceFeeAmount = null;
        })
        .finally(() => {
          this.isFetchingConvenienceFee = false;
        });
    }, 300)
  },
  methods: {
    ...mapActions([
      "getPosLocations",
      "updateOrCreatePaymentRequest",
      "getPaymentRequest",
      "getPatientClient",
      "updatePaymentRequest",
      "payWithPos",
      "getConvenienceFee",
      "getCollectlyUser",
      "getActivePosWorkflowSettings"
    ]),
    ...mapMutations(["logout", "authError", "relogin"]),
    async fetchPaymentRequestData() {
      this.isLoadingPaymentRequest = true;

      try {
        const paymentReqId = await this.updateOrCreatePaymentRequest();
        const paymentRequest = await this.getPaymentRequest(paymentReqId);

        const { id, items } = paymentRequest || {};

        if (!items || items.length === 0) return;

        this.paymentRequestId = id;

        this.setInitialPaymentAmounts(items);
      } catch (error) {
        if (!error.response || error.response.status !== 401) return;

        this.logout();
        this.authError();
        this.relogin();
      } finally {
        this.isLoadingPaymentRequest = false;
      }
    },
    async fetchLocations() {
      this.isLoadingLocations = true;

      try {
        const locations = await this.getPosLocations();
        this.locations = locations;
      } finally {
        this.isLoadingLocations = false;
      }
    },
    setInitialPaymentAmounts(items) {
      const currentBalanceItem = items.find(
        i => i.name === PAYMENT_REQUEST_ITEM_NAMES.currentBalance
      );

      this.currentBalance =
        currentBalanceItem && currentBalanceItem.amount
          ? currentBalanceItem.amount
          : null;

      const coPayItem = items.find(
        i => i.name === PAYMENT_REQUEST_ITEM_NAMES.copay
      );

      this.coPay = coPayItem && coPayItem.amount ? coPayItem.amount : null;

      const depositItem = items.find(
        i => i.name === PAYMENT_REQUEST_ITEM_NAMES.deposit
      );

      this.preServiceDeposit =
        depositItem && depositItem.amount ? depositItem.amount : null;
    },
    async submitForm() {
      this.paymentErrorText = null;

      const items = [
        {
          amount: this.currentBalance,
          name: PAYMENT_REQUEST_ITEM_NAMES.currentBalance
        },
        {
          amount: this.coPay,
          name: PAYMENT_REQUEST_ITEM_NAMES.copay,
          amounts_routing: [
            {
              amount: this.coPay,
              location_id: this.coPayLocationId
            }
          ]
        },
        {
          amount: this.preServiceDeposit,
          name: PAYMENT_REQUEST_ITEM_NAMES.deposit,
          amounts_routing: [
            {
              amount: this.preServiceDeposit,
              location_id: this.preServiceDepositLocationId
            }
          ]
        }
      ];

      const filteredItems = items.filter(i => !!i.amount);

      this.isTakingPayment = true;

      try {
        await this.updatePaymentRequest({
          id: this.paymentRequestId,
          items: filteredItems
        });

        const posPaymentResponse = await this.payWithPos({
          terminalId: this.terminalId,
          paymentRequestId: this.paymentRequestId,
          signPatientForAutoPay: this.signPatientForAutoPay
        });

        this.$emit("posWorkflowStarted", {
          terminalId: this.terminalId,
          posPaymentResponse
        });
      } catch (error) {
        if (
          !error.response ||
          !error.response.data ||
          !error.response.data.error_text
        ) {
          // eslint-disable-next-line no-console
          console.error(error);

          this.paymentErrorText = "Something went wrong. Please try again.";
          return;
        }

        this.paymentErrorText = error.response.data.error_text;
      } finally {
        this.isTakingPayment = false;
      }
    }
  },
  computed: {
    hasAllRequiredValues() {
      const hasValidCopayValue = this.coPay ? !!this.coPayLocationId : true;
      const hasValidDepositValue = this.preServiceDeposit
        ? !!this.preServiceDepositLocationId
        : true;

      const hasValidPaymentAmounts =
        this.currentBalance || (hasValidCopayValue && hasValidDepositValue);

      const noAmountEntered =
        !this.currentBalance && !this.coPay && !this.preServiceDeposit;

      if (noAmountEntered) return false;

      return this.terminalId && hasValidPaymentAmounts;
    },

    isLoading() {
      return this.isLoadingPaymentRequest || this.isLoadingClient;
    },
    totalAmount() {
      return (
        (this.currentBalance || 0) +
        (this.coPay || 0) +
        (this.preServiceDeposit || 0)
      );
    },
    validationErrors() {
      const errors = {};

      const currentBalance = this.currentBalance || 0;

      if (currentBalance !== 0 && currentBalance > this.accountBalance) {
        errors.currentBalance =
          this.familyGroupRole === "guarantor"
            ? "Exceeds current outstanding balance.\nYou can charge parts of the balance that are not billed directly from dependent’s account."
            : "Exceeds current outstanding balance.\nUse copay / deposit fields for excess amount.";
      }

      return errors;
    },
    ...mapState({
      autoPayConsentLink(state) {
        const { first_name, last_name, client_name } = state.patient || {};

        if (!webAppURL || !first_name || !last_name || !client_name) {
          return null;
        }

        return (
          webAppURL +
          `/consent?patientName=${state.patient.first_name} ${state.patient.last_name}&practiceName=${state.patient.client_name}`
        );
      },
      terminals: state => {
        return state.terminals || [];
      },
      accountBalance: state => {
        const accountBalance =
          state.patient.family_group_role === "guarantor"
            ? state.patient.family_balance
            : state.patient.current_balance;

        return accountBalance;
      },
      familyGroupRole: state => {
        return state.patient.family_group_role;
      }
    })
  },
  async created() {
    const storedUser = userStorage.get();

    if (storedUser && storedUser.id) {
      this.getCollectlyUser(storedUser.id).then(user => {
        if (!user) return;

        const { default_payment_location_id, default_pos_terminal_id } = user;

        this.terminalId = default_pos_terminal_id || undefined;
        this.coPayLocationId = default_payment_location_id || undefined;
        this.preServiceDepositLocationId =
          default_payment_location_id || undefined;
      });
    }

    try {
      const workflowSettings = await this.getActivePosWorkflowSettings();
      this.workflowSettings = workflowSettings;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }

    const isPosTerminalAutopayConsentDialogEnabled = !!this.workflowSettings?.find((s) => s.name === 'collect_autopay_consent:is_enabled')?.value;
    

    this.getPatientClient().then(client => {
      this.isCheckboxSignPatientForAutoPayForPOSVisible = 
        !isPosTerminalAutopayConsentDialogEnabled && 
        (client.meta && !client.meta.should_hide_checkbox_sign_patient_for_auto_pay_for_pos);

      this.signPatientForAutoPay =
        !!client.meta.should_precheck_sign_patient_for_auto_pay ?? true;

      if (
        // should_hide_checkbox_sign_patient_for_auto_pay_for_pos hides the checkbox — means its value should be sent as false
        // isPosTerminalAutopayConsentDialogEnabled - means that consent is collected through POS terminal later
        client.meta?.should_hide_checkbox_sign_patient_for_auto_pay_for_pos || isPosTerminalAutopayConsentDialogEnabled
      ) {
        this.signPatientForAutoPay = false;
      }

      this.nonCCConvenienceFeeChargingIsDisabled =
        !!client.meta.non_cc_convenience_fee_charging_is_disabled ?? false;
    });

    this.fetchPaymentRequestData();
    this.fetchLocations();
  }
};
</script>

<template>
  <div class="posModalContent">
    <div
      v-if="isLoading"
      class="display-flex align-center gap-2xs-ppNew loadingContainer"
    >
      <font-awesome-icon
        icon="circle-notch"
        spin
        size="2x"
        class="blue-100-ppNew"
      ></font-awesome-icon>
      <div class="text-l-medium">Loading payment data...</div>
    </div>

    <div v-else>
      <div class="display-flex flex-column gap-l-ppNew">
        <div>
          <div class="heading-m grey-100-ppNew">Payment</div>

          <div class="text-m grey-70-ppNew">
            Enter the amounts you want to charge
          </div>
        </div>

        <div class="display-flex flex-column gap-m-ppNew">
          <div class="display-flex flex-column gap-4xs-ppNew">
            <div class="text-s grey-70-ppNew">POS terminal</div>

            <b-field>
              <b-select
                placeholder="Select a terminal"
                size="is-small"
                expanded
                v-model="terminalId"
              >
                <option
                  v-for="terminal in terminals"
                  :value="terminal.id"
                  :key="terminal.id"
                  >{{ terminal.name }}</option
                >
              </b-select>
            </b-field>
          </div>

          <div
            class="display-flex flex-column gap-4xs-ppNew smallPaymentInputContainer"
          >
            <div class="text-s grey-70-ppNew">Current Balance</div>

            <CurrencyInput
              v-model="currentBalance"
              :validationError="validationErrors.currentBalance"
            />

            <div
              v-if="!!validationErrors.currentBalance"
              class="validationErrorContainer"
            >
              <div class="red-120-ppNew text-xs validationError">
                {{ validationErrors.currentBalance }}
              </div>
            </div>
          </div>

          <div class="display-flex align-center gap-s-ppNew">
            <div
              class="display-flex flex-column gap-4xs-ppNew smallPaymentInputContainer"
            >
              <div class="text-s grey-70-ppNew">Co-Pay</div>

              <CurrencyInput v-model="coPay" />
            </div>

            <div class="text-s grey-70-ppNew">
              <div class="padding-top-s-ppNew">to</div>
            </div>

            <div
              class="display-flex flex-column gap-4xs-ppNew locationContainer"
            >
              <div class="text-s grey-70-ppNew">Service Location</div>

              <b-field>
                <b-select
                  v-model="coPayLocationId"
                  placeholder="Select a location"
                  size="is-small"
                  :loading="isLoadingLocations"
                  :disabled="isLoadingLocations"
                  expanded
                  required
                >
                  <option
                    v-for="location in locations"
                    :value="location.id"
                    :key="location.id"
                    >{{ location.name }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>

          <div class="display-flex align-center gap-s-ppNew">
            <div
              class="display-flex flex-column gap-4xs-ppNew smallPaymentInputContainer"
            >
              <div class="text-s grey-70-ppNew">Pre-service Deposit</div>

              <CurrencyInput v-model="preServiceDeposit" />
            </div>

            <div class="text-s grey-70-ppNew">
              <div class="padding-top-s-ppNew">to</div>
            </div>

            <div
              class="display-flex flex-column gap-4xs-ppNew locationContainer"
            >
              <div class="text-s grey-70-ppNew">Service Location</div>

              <b-field>
                <b-select
                  v-model="preServiceDepositLocationId"
                  placeholder="Select a location"
                  size="is-small"
                  :loading="isLoadingLocations"
                  :disabled="isLoadingLocations"
                  expanded
                  required
                >
                  <option
                    v-for="location in locations"
                    :value="location.id"
                    :key="location.id"
                    >{{ location.name }}</option
                  >
                </b-select>
              </b-field>
            </div>
          </div>
        </div>

        <div class="display-flex flex-column gap-s-ppNew">
          <div class="display-flex align-center gap-2xs-ppNew">
            <font-awesome-icon
              icon="info-circle"
              size="1x"
              class="blue-120-ppNew"
            ></font-awesome-icon>

            <div class="text-m-semibold">
              Presented card will be stored on file.
            </div>
          </div>

          <div v-if="isCheckboxSignPatientForAutoPayForPOSVisible">
            <b-field class="checkboxField margin-bottom-0-ppNew">
              <b-checkbox
                class="text-m-semibold checkboxLabel"
                v-model="signPatientForAutoPay"
              >
                Auto-charge this patient for any future balance using any card
                on file.</b-checkbox
              >
            </b-field>

            <!-- TODO: show only when checked -->
            <div class="grey-100-ppNew margin-left-xl-ppNew text-s">
              <b>Note:</b> To avoid patient complaints, ensure that the patient
              has been informed that they will be automatically charged for any
              future balance using any of their cards on file.
              <span v-if="autoPayConsentLink"
                ><br />If they do consent, you can ask them to sign this
                <a :href="autoPayConsentLink" target="_blank"
                  >Authorization form</a
                >.</span
              >
            </div>
          </div>
        </div>

        <div class="display-flex flex-column align-end gap-2xs-ppNew">
          <div v-if="paymentErrorText" class="red-120-ppNew text-m">
            Error: {{ paymentErrorText }}
          </div>

          <div
            class="posModalActions display-flex align-center gap-2xs-ppNew"
            v-bind:class="
              !convenienceFeeAmount ? 'justify-end' : 'justify-space-between'
            "
          >
            <div
              v-if="convenienceFeeAmount"
              class="transactionFeeMessage display-flex gap-3xs-ppNew text-s grey-100-ppNew"
            >
              <div
                v-if="isFetchingConvenienceFee"
                class="display-flex align-center gap-2xs-ppNew loadingContainer"
              >
                <font-awesome-icon
                  icon="circle-notch"
                  spin
                  class="grey-100-ppNew text-l"
                ></font-awesome-icon>
              </div>

              <span v-if="!isFetchingConvenienceFee" class="text-s-medium">
                {{
                  this.nonCCConvenienceFeeChargingIsDisabled
                    ? "Credit card payments will have a transaction fee of"
                    : "Includes transaction fee of"
                }}
                {{ convenienceFeeAmount | toUSD }}
              </span>
            </div>

            <b-button
              @click="submitForm"
              type="is-primary"
              class="text-m-medium"
              :disabled="
                isLoadingLocations ||
                  !hasAllRequiredValues ||
                  isTakingPayment ||
                  isFetchingConvenienceFee ||
                  Object.keys(validationErrors).length > 0
              "
              :loading="isFetchingConvenienceFee || isTakingPayment"
              >Charge
              {{
                (this.nonCCConvenienceFeeChargingIsDisabled
                  ? totalAmount
                  : totalAmount + (convenienceFeeAmount || 0)) | toUSD
              }}
              with POS
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.posModalContent {
  display: grid;
  place-items: center;
  flex: 1 0 auto;
}

.smallPaymentInputContainer {
  flex: 1 0 auto;
  max-width: 164px;
}

.validationErrorContainer {
  position: relative;
  height: 32px;
}

.validationError {
  position: absolute;
  width: 264px;
  top: 0;
  left: 0;
  white-space: pre-line;
}

.locationContainer {
  flex: 1 0 auto;
}

.checkboxField {
  margin-bottom: 0 !important;
}

.checkboxLabel {
  margin-right: 0 !important;
}

.posModalActions {
  width: 100%;
}

.transactionFeeMessage {
  max-width: 60%;
}
</style>
