import axios from "axios";
import { cloneDeep, remove } from "lodash";
import _orderBy from "lodash/orderBy";
import Vue from "vue";
import Vuex from "vuex";
import { userStorage } from "@/services/userStorage";
import { startLogoutTimer } from "./startLogoutTimer";
import { PAYMENT_REQUEST_ITEM_NAMES } from "../data/payment.data.js";

Vue.use(Vuex);

let api = axios.create({
  baseURL: `/api/v2/`
});

let store = new Vuex.Store({
  state: {
    status: "",
    user: null,
    user_id: null,
    session_id: null,
    patientId: null,
    practiceId: null,
    departmentId: null,
    username: null,
    patient: null,
    // patients is a temporary container for the case when there are multiple patients in Collectly with the same
    // externalPatientId. This usually happens when there is a practice split.
    patients: null,
    error: null,
    accessDenied: false,
    messages: [],
    taskId: null,
    lastThreadId: null,
    dialogOpen: {
      editPlan: false,
      payClaim: false,
      addCard: false,
      chargeCard: false,
      requestConsent: false,
      posPayment: false
    },
    dialogCallback: {
      requestConsent: () => {}
    },
    chargeMode: "add",
    isDialogOpen: false,
    patientCards: [],
    reloginPromiseReject: null,
    reloginPromiseResolve: null,
    patientConsents: {},
    locations: null,
    features: [],
    companies: [],
    productClaimCheckoutData: null,
    isFetchingPatient: false,
    isAuthenticating: false,
    isResyncingBalance: false,
    resetTimer: null,
    terminals: []
  },
  mutations: {
    authRequest(state) {
      state.status = "loading";
      state.error = null;
    },
    otpAuthRequest(state) {
      state.status = "otp-required-loading";
      state.error = null;
    },
    authOTPRequired(state, data) {
      state.status = "otp-required";
      state.user_id = data.user_id;
      state.session_id = data.session_id;
      state.otp_email =
        (data["2fa_flow_email"] && data["2fa_flow_email"].email) || null;
    },
    authOTPRequiredError(state, error) {
      state.status = "otp-required-error";
      state.error = error;
    },
    resendEmailRequest(state) {
      state.status = "otp-required-loading";
      state.error = null;
    },
    resendEmailError(state, error) {
      state.status = "otp-required";
      state.error = error;
    },
    resendEmailSuccess(state) {
      state.status = "otp-required";
      state.error = null;
    },
    authSuccess(state, user) {
      state.status = "success";
      state.user = user;
      state.is_readonly_user = user?.role < 4 ?? false; // role 4 is a frontdesk
      state.accessDenied = false;
    },
    authError(state) {
      state.status = "error";
    },
    accessDenied(state) {
      state.status = "error";
      state.accessDenied = true;
    },
    logout(state) {
      state.status = "";
      state.user = null;
      state.user_id = null;
      state.is_readonly_user = null;
      state.session_id = null;
      state.accessDenied = false;
    },
    patientIdChanged(state, patientId) {
      state.patientId = patientId;
      state.status = "loading";
      state.patient = null;
      state.patients = null;
      state.error = null;
      state.locations = null;
    },
    patientLoading(state) {
      state.selectFromMultiplePatients = false;
      state.patient = null;
      state.patients = null;
    },
    setTerminals(state, terminals) {
      state.terminals = terminals;
    },
    setPatientFetchingStatus(state, isFetching) {
      state.isFetchingPatient = isFetching;
    },
    setIsAuthenticating(state, isAuthenticating) {
      state.isAuthenticating = isAuthenticating;
    },
    setIsResyncingBalance(state, isResyncing) {
      state.isResyncingBalance = isResyncing;
    },
    patientLoaded(state, patient) {
      state.selectFromMultiplePatients = false;
      state.patient = patient;
      state.status = "success";
    },
    patientsLoaded(state, patients) {
      state.patients = patients;
      state.selectFromMultiplePatients = true;
      state.status = "success";
    },
    resetPatientSelect(state) {
      state.patient = null;
      state.selectFromMultiplePatients = true;
    },
    practiceIdChanged(state, data) {
      state.practiceId = data.practiceId;
      state.departmentId = data.departmentId;
      state.connectionType = data.connectionType || "athenahealth";
      state.username = data.username;
    },
    setFeatures(state, features) {
      state.features = features;
    },
    apiError(state, error) {
      state.error = error;
      state.status = "error";
    },
    setMessages(state, messages) {
      state.messages = messages;
      // Find latest thread Id
      let lastThread = null,
        lastId = null;
      // TODO: We calculate the last thread wrong. We check inbound and if the thread doesn't include patient messages it will not work
      messages.forEach(m => {
        if (m.inbound && m.thread && (!lastId || lastId < m.id)) {
          lastThread = m.thread;
        }
      });
      state.lastThreadId = lastThread;
    },
    addMessage(state, message) {
      state.messages = state.messages || [];
      state.messages.push(message);
    },
    waitForTask(state, taskId) {
      state.status = "loading";
      state.taskId = taskId;
    },
    closeThread(state) {
      state.patient.last_billing_cycle.paused = false;
    },
    setChargeMode(state, value) {
      state.chargeMode = value;
    },
    /** @param {import('../types/product.types').TEcwProductCheckoutData} value */
    setProductClaimCheckoutData(state, value) {
      state.productClaimCheckoutData = value;
    },
    setDlgVisible(state, data) {
      let openCount = 0;
      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(state.dialogOpen)) {
        openCount += value ? 1 : 0;
      }
      let newOpenCount =
        openCount +
        (data.visible ? 1 : 0) - // new state (dialog visible/not)
        (state.dialogOpen[data.name] ? 1 : 0); // previous dialog state

      state.dialogOpen[data.name] = data.visible;
      if (state.dialogCallback[data.name] && data.visible) {
        state.dialogCallback[data.name] = data.onFinish;
      }

      if (openCount > 0 !== newOpenCount > 0) {
        window.parent.postMessage(
          { eventName: "fullscreen", isFullscreen: newOpenCount > 0 },
          "*"
        );
      }
    },
    updatePlan(state, plan) {
      if (!state.patient) return;
      remove(state.patient.payment_plans, p => p.id == plan.id);
      state.patient.payment_plans.splice(0, 1, plan);
    },
    addCard(state, card) {
      card.cardIconClass = (card.type || "").replace(" ", "").toLowerCase();
      state.patientCards.push(card);
    },
    updateCards(state, cards) {
      state.patientCards = cards;
    },
    updateConsents(state, consents) {
      state.patientConsents = consents;
    },
    reloginPromise(state, val) {
      state.reloginPromiseReject = val.reject;
      state.reloginPromiseResolve = val.resolve;
    },
    updateLocations(state, locations) {
      state.locations = locations;
    },
    setCompanies(state, companies) {
      state.companies = companies;
    },
    updatePatientAppointment(state, appointment) {
      if (!state.patient && !state.patients) {
        return;
      }

      const patient = state.patient || state.patients[0];

      if (!patient || !patient.next_appointments) {
        return;
      }

      patient.next_appointments = state.patient.next_appointments.map(item => {
        if (item.id === appointment.id) {
          return appointment;
        }

        return item;
      });
    },
    setResetTimer(state, resetTimer) {
      state.resetTimer = resetTimer;
    }
  },
  actions: {
    selectPatient({ commit }, p) {
      commit("patientLoaded", p);
    },
    resetPatientSelect({ commit }) {
      commit("resetPatientSelect");
    },
    showPayClaimDlg({ commit }, flag) {
      commit("setDlgVisible", { name: "payClaim", visible: flag });
    },
    showEditPlanDlg({ commit }, flag) {
      commit("setDlgVisible", { name: "editPlan", visible: flag });
    },
    showAddCardDlg({ commit }, flag) {
      if (flag) {
        commit("setChargeMode", "add");
      }
      commit("setDlgVisible", { name: "addCard", visible: flag });
    },
    showChargeDlg({ commit }, flag) {
      if (flag) {
        commit("setChargeMode", "charge");
      }
      commit("setDlgVisible", { name: "addCard", visible: flag });
    },
    showRequestConsentDlg({ commit }, flag) {
      return new Promise(resolve => {
        commit("setDlgVisible", {
          name: "requestConsent",
          visible: flag,
          onFinish: resolve
        });
      });
    },
    showPosPaymentDialog({ commit }, flag) {
      commit("setDlgVisible", { name: "posPayment", visible: flag });
    },
    updatePaymentPlan({ commit }, planData) {
      const method = planData.id ? "PUT" : "POST";
      const url =
        "/patients/" +
        planData.patientId +
        "/plans/" +
        (planData.id ? planData.id : "subscribe");
      return new Promise((resolve, reject) => {
        api({
          method: method,
          url: url,
          data: planData
        })
          .then(resp => {
            if (resp.data.next_payment) {
              resp.data.next_payment = new Date(
                Date.parse(resp.data.next_payment)
              );
            }
            commit("updatePlan", resp.data);
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
    },
    requestConsent({ commit }, { planData, contact }) {
      const method = "POST";
      const url = `/patients/${this.state.patient.id}/plans/request_consent`;
      return new Promise((resolve, reject) => {
        api({ method, url, data: { plan: planData, contact: contact } })
          .then(resp => {
            commit("updatePlan", resp.data);
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
    },
    resendConsentRequest({ commit }, { consent, contact }) {
      const method = "POST";
      const url = `/patients/${this.state.patient.id}/consents/${consent.id}/resend`;
      return new Promise((resolve, reject) => {
        api({ method, url, data: contact })
          .then(resp => {
            const newConsents = cloneDeep(this.state.patientConsents);
            newConsents[resp.data.terms.payment_plan.id] = resp.data;
            commit("updateConsents", newConsents);
            resolve(resp.data);
          })
          .catch(err => {
            reject(err.response.data);
          });
      });
    },
    loadConsents({ commit }) {
      return api
        .get("/patients/" + this.state.patient.id + "/consents")
        .then(resp => {
          const consents = resp.data
            .filter(c => c.type === "payment_plan_subscribe")
            .reduce(
              (acc, c) => Object.assign(acc, { [c.terms.payment_plan.id]: c }),
              {}
            );
          commit("updateConsents", consents);
          return consents;
        });
    },
    loadCards({ commit }) {
      return api
        .get("/patients/" + this.state.patient.id + "/cards")
        .then(resp => {
          resp.data = resp.data.filter(card => card.status !== "deleted");
          resp.data.forEach(card => {
            card.cardIconClass = (card.type || "")
              .replace(" ", "")
              .toLowerCase();
          });

          commit("updateCards", resp.data);
          return resp.data;
        });
    },
    storeCard({ commit }, cardData) {
      let url = "/patients/" + this.state.patient.id + "/cards";
      if (cardData.one_time) {
        url = "/patients/" + this.state.patient.id + "/cards/charge-card";
        cardData.card_type = cardData.type;
      }
      cardData.display =
        "**** " +
        cardData.last4 +
        " EXP " +
        cardData.exp_month.toString().padStart(2, 0) +
        "/" +
        cardData.exp_year.toString().substring(2);

      return api.post(url, cardData).then(resp => {
        if (!cardData.one_time) {
          commit("addCard", resp.data);
        }
        return resp.data;
      });
    },
    chargeCard({ dispatch }, paymentData) {
      let url =
        "/patients/" +
        this.state.patient.id +
        "/cards/" +
        paymentData.card_id +
        "/charge";

      return api.post(url, paymentData).then(resp => {
        dispatch("getPatient", { patientExternalId: this.state.patientId });
        return resp.data;
      });
    },
    login({ commit, dispatch }, options) {
      const { email, password, shouldFetchPatient } = options;
      const credentials = { email, password };

      commit("authRequest");
      commit("setIsAuthenticating", true);
      return api
        .post("/token?exclude_token_from_json=true", credentials)
        .then(resp => {
          window.parent.postMessage(
            { eventName: "storeCredentials", credentials: credentials },
            "*"
          );
          if (resp.data["2fa_required"] === true) {
            commit("setIsAuthenticating", false);
            commit("authOTPRequired", resp.data);
            return { is_otp_required: true };
          }
          const user = resp.data;
          userStorage.save(user);
          dispatch("authSuccess", user);

          if (shouldFetchPatient && this.state.patientId) {
            this.dispatch("getPatient", {
              patientExternalId: this.state.patientId
            });
          }

          return resp.data;
        })
        .catch(err => {
          commit("authError");
          commit(
            "apiError",
            err &&
              err.response &&
              err.response.data &&
              err.response.data.error_text
          );
          userStorage.clear();
          throw err;
        })
        .finally(() => {
          commit("setIsAuthenticating", false);
        });
    },
    logout({ commit }) {
      commit("logout");
      userStorage.clear();
      window.parent.postMessage({ eventName: "clearCredentials" }, "*");
    },
    OTPValidate({ commit, dispatch }, options) {
      const {
        code,
        user_id,
        session_id,
        otp_email,
        shouldFetchPatient
      } = options;
      const credentials = { code, user_id, session_id };

      if (otp_email) {
        credentials.flow = "email";
      }

      commit("otpAuthRequest");
      commit("setIsAuthenticating", true);
      return api
        .post("/token/otp/validate?exclude_token_from_json=true", credentials)
        .then(resp => {
          const user = resp.data;
          userStorage.save(user);
          dispatch("authSuccess", user);

          if (shouldFetchPatient && this.state.patientId) {
            this.dispatch("getPatient", {
              patientExternalId: this.state.patientId
            });
          }

          return resp.data;
        })
        .catch(err => {
          commit("authOTPRequiredError", err.response.data.error_text);
          userStorage.clear();
          window.parent.postMessage({ eventName: "clearCredentials" }, "*");
          return err;
        })
        .finally(() => {
          commit("setIsAuthenticating", false);
        });
    },
    resendOtpEmail({ commit }, options) {
      const { user_id, session_id } = options;

      commit("resendEmailRequest");
      commit("setIsAuthenticating", true);
      return api
        .post("/token/otp/email/resend", { user_id, session_id })
        .then(resp => {
          commit("resendEmailSuccess");

          return resp.data;
        })
        .catch(err => {
          const response = err.response;
          let message = response.statusText;

          if (response.data) {
            message =
              response.data.error || response.data.error_text || message;
          }

          commit("resendEmailError", message);

          return err;
        })
        .finally(() => {
          commit("setIsAuthenticating", false);
        });
    },
    relogin({ commit }) {
      return new Promise((resolve, reject) => {
        window.parent.postMessage({ eventName: "relogin" }, "*");
        commit("reloginPromise", { resolve, reject });
      });
    },
    reloginResult({ dispatch }, result) {
      let resolve = this.state.reloginPromiseResolve;
      let reject = this.state.reloginPromiseReject;
      if (!resolve || !reject) return;
      if (result.success) {
        dispatch("login", result.credentials)
          .then(loginResult => {
            if (loginResult && loginResult.is_otp_required) {
              reject();
              dispatch("logout");
            } else {
              resolve();
            }
          })
          .catch(() => {
            reject();
            dispatch("logout");
          });
      } else {
        reject();
        dispatch("logout");
      }
    },
    async getCollectlyUser(_, userId) {
      try {
        const resp = await api.get(`/users/${userId}`);

        if (!resp.data) return;

        return resp.data;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    getPatient(
      { dispatch, commit },
      { patientExternalId, shouldReloginOn401 = true }
    ) {
      return new Promise((resolve, reject) => {
        commit("authRequest");
        commit("patientLoading");
        commit("setPatientFetchingStatus", true);

        let payload = {
          external_id: patientExternalId,
          external_client_id: this.state.practiceId,
          connection_type: this.state.connectionType,
          username: this.state.username
        };
        if (this.state.departmentId) {
          payload.external_department_id = this.state.departmentId;
        }

        api
          .post("/patients/lookup_external", payload)
          .then(resp => {
            let patients = resp.data;
            patients.forEach(pt => {
              if (pt.payment_plans) {
                pt.payment_plans.forEach(p => {
                  p.next_payment = new Date(Date.parse(p.next_payment));
                });
              }
            });
            let patient = patients[0];
            commit("apiError", null);
            if (patients.length === 1) {
              commit("patientLoaded", patient);
            } else {
              commit("patientsLoaded", patients);
            }
            resolve(patient);
          })
          .catch(r => {
            if (r.response && r.response.status === 403) {
              commit("accessDenied");
            }
            if (r.response && r.response.status === 401) {
              commit("logout");
              commit("authError");

              if (!shouldReloginOn401) return;

              dispatch("relogin").then(() => {
                // We need to add shouldReloginOn401: false to avoid infinite loop
                dispatch("getPatient", {
                  patientExternalId,
                  shouldReloginOn401: false
                }).then(r2 => {
                  resolve(r2);
                });
              });
            } else {
              commit("apiError", r.response.data.error_text);
              reject(r.response.data);
            }
          })
          .finally(() => {
            commit("setPatientFetchingStatus", false);
          });
      });
    },
    async getConvenienceFee({ state }, amount) {
      const resp = await api.post("/payments/convenience_fee", {
        amount,
        client_id: state.patient.client,
        company_id: state.patient.company
      });

      return resp.data;
    },
    getPatientClient({ state }) {
      return api.get(`clients/${state.patient.client}`).then(resp => resp.data);
    },
    fetchPublicClientSettings() {
      const payload = {
        connection_type: this.state.connectionType,
        connection_username: this.state.username,
        connection_id: this.state.practiceId
      };

      return api.post("/clients/public-settings", payload).then(resp => {
        return resp.data;
      });
    },
    resync({ dispatch, commit }, patientId) {
      commit("authRequest");
      return api
        .post("/patients/sync", {
          patient_id: patientId
        })
        .then(resp => {
          commit("waitForTask", resp.data.task_id);
          return resp.data.task_id;
        })
        .then(taskId => {
          this.dispatch("pollTask", taskId);
        })
        .catch(error => {
          if (error && error.response && error.response.status === 401) {
            commit("logout");
            commit("authError");
            dispatch("relogin");
            commit(
              "apiError",
              error &&
                error.response &&
                error.response.data &&
                error.response.data.error_text
            );
          } else {
            commit(
              "apiError",
              error &&
                error.response &&
                error.response.data &&
                error.response.data.error_text
            );
          }
        });
    },
    resyncBalance({ commit }, patientId) {
      commit("setIsResyncingBalance", true);

      return api
        .post("/patients/sync-balance", {
          patient_id: patientId
        })
        .then(resp => {
          if (!resp || !resp.data) {
            throw new Error("Failed to resync balance");
          }

          if (resp.data.status === "SUCCESS") {
            return resp.data;
          }

          throw new Error(resp.data.error);
        })
        .finally(() => {
          commit("setIsResyncingBalance", false);
        });
    },
    resyncBalanceForExternal({ dispatch }, patientExternalId) {
      return dispatch("getPatient", {
        patientExternalId,
        shouldReloginOn401: false
      }).then(patient =>
        dispatch("resyncBalance", patient.id).then(() => patient.id)
      );
    },
    getMessages({ commit }, patientId) {
      return api
        .get(
          "/messages?where=" +
            encodeURIComponent(JSON.stringify({ patient: patientId }))
        )
        .then(resp => {
          let messages = _orderBy(resp.data, ["created_at"], ["asc"]);
          commit("setMessages", messages);
          return messages;
        });
    },
    sendMessage({ commit, state }, data) {
      if (state.is_readonly_user) {
        return;
      }

      return api
        .post("/messages", {
          patient_id: data.patientId,
          text: data.text,
          type: "chat"
        })
        .then(resp => {
          commit("addMessage", resp.data);
          return resp.data;
        });
    },
    closeThread({ commit }) {
      return api
        .patch("/threads/" + this.state.lastThreadId, {
          paused_before: null,
          status: "closed"
        })
        .then(resp => {
          commit("closeThread", resp.data);
          return resp.data;
        });
    },
    getCurrentCompanyFeatures({ commit, state }) {
      if (!state.patient && !state.patient.company) return;

      return api
        .get("/features", {
          params: {
            per_page: 2000,
            where: {
              company_id: state.patient.company
            }
          }
        })
        .then(resp => {
          commit("setFeatures", resp.data);
          return resp.data;
        });
    },
    getClientTerminals({ state, commit }) {
      if (!state.patient || !state.patient.client) return;

      return api
        .get("/pos-terminals", {
          params: {
            per_page: 500,
            sort: "-created_at",
            where: {
              client_id: state.patient.client,
              status: "paired"
            }
          }
        })
        .then(resp => {
          commit("setTerminals", resp.data);
          return resp.data;
        });
    },
    getLocations({ commit, state }, name = null) {
      if (!state.patient && !state.patients) return;
      let patient = state.patient || state.patients[0];
      let filter = {
        client_id: patient.client,
        company_id: patient.company
      };
      if (name) filter.name = name;

      return api
        .get("/locations", {
          params: {
            where: filter,
            per_page: 100
          }
        })
        .then(resp => {
          const locations = resp.data;
          commit("updateLocations", locations);
          return locations;
        });
    },
    getPosLocations({ state }) {
      return api
        .get("/locations", {
          params: {
            where: {
              client_id: state.patient.client,
              enabled: true
            },
            sort: "name",
            per_page: 300
          }
        })
        .then(resp => {
          return resp.data;
        });
    },
    async getLatestUnpaidPaymentRequest({ state }) {
      const resp = await api.get("/payment-requests/latest-unpaid", {
        params: {
          patient_id: state.patient.id,
          // allows to get a payment request from the closest upcoming appointment
          from_appointment: true
        }
      });

      return resp.data;
    },
    async createPaymentRequest({ state }, items) {
      const resp = await api.post("/payment-requests", {
        items,
        patient_id: state.patient.id
      });

      return resp.data.id;
    },
    async updatePaymentRequest(_, { id, items }) {
      const resp = await api.put(`/payment-requests/${id}/items`, {
        items
      });

      return resp.data.payment_request_id;
    },
    async updateOrCreatePaymentRequest({ dispatch, state }) {
      const accountBalance =
        state.patient.family_group_role === "guarantor"
          ? state.patient.family_balance
          : state.patient.current_balance;

      const currentBalanceAmount = accountBalance > 0.5 ? accountBalance : 0;

      const updatedCurrentBalanceItem = {
        amount: currentBalanceAmount,
        name: PAYMENT_REQUEST_ITEM_NAMES.currentBalance
      };

      try {
        const latestPaymentReq = await dispatch(
          "getLatestUnpaidPaymentRequest"
        );

        if (
          !latestPaymentReq ||
          !latestPaymentReq.id ||
          !latestPaymentReq.items ||
          !latestPaymentReq.items.length
        ) {
          return dispatch("createPaymentRequest", [updatedCurrentBalanceItem]);
        }

        const newItems = latestPaymentReq.items.slice();

        const currentBalanceItemIndex = newItems.findIndex(
          item => item.name === PAYMENT_REQUEST_ITEM_NAMES.currentBalance
        );

        if (currentBalanceItemIndex !== -1) {
          newItems.splice(
            currentBalanceItemIndex,
            1,
            updatedCurrentBalanceItem
          );
        }

        const updatedPayReqId = dispatch("updatePaymentRequest", {
          id: latestPaymentReq.id,
          items: newItems
        });

        return updatedPayReqId;
      } catch (error) {
        if (error.response.status !== 404) throw error;

        return dispatch("createPaymentRequest", [updatedCurrentBalanceItem]);
      }
    },
    async getPaymentRequest(_, paymentRequestId) {
      const resp = await api.get(`/payment-requests/${paymentRequestId}`);

      return resp.data;
    },
    async getActivePosWorkflow({ state }) {
      const resp = await api.get(
        `/patients/${state.patient.id}/pos-terminal-workflow`
      );

      return resp.data;
    },
    async getActivePosWorkflowSettings({ state }) {
      const resp = await api.get(
        `/clients/${state.patient.client}/pos-terminal-workflow-settings`
      );

      return resp.data;
    },
    async payWithPos({ state }, payload) {
      const { terminalId, paymentRequestId, signPatientForAutoPay } = payload;

      const resp = await api.post(
        `/pos-terminals/${terminalId}/workflows/pay`,
        {
          patient_id: state.patient.id,
          payment_request_id: paymentRequestId,
          sign_patient_for_auto_pay: signPatientForAutoPay
        }
      );

      return resp.data;
    },
    async getPosPaymentStatus(_, { terminalId, workflowId }) {
      const resp = await api.get(
        `/pos-terminals/${terminalId}/workflows/${workflowId}`
      );

      return resp.data;
    },
    async cancelPosWorkflow(_, { terminalId, workflowId }) {
      const resp = await api.post(
        `/pos-terminals/${terminalId}/workflows/${workflowId}/cancel`
      );

      return resp.data;
    },
    async fetchCurrentCompany({ commit, state }) {
      if (!state.patient || !state.patient.company) return;

      return api
        .get("/companies", {
          params: {
            per_page: 1000,
            where: {
              // It's important to pass String, otherwise filter won't work
              id: String(state.patient.company)
            }
          }
        })
        .then(resp => {
          if (!resp || !resp.data) return [];

          commit("setCompanies", resp.data);
          return resp.data;
        });
    },
    pollTask({ commit }, taskId) {
      return api
        .get("/tasks/" + taskId)
        .then(resp => {
          let data = resp.data || { status: "FAILURE" };
          let status = (data.data || {}).status || data.status;
          switch (status) {
            case "SUCCESS":
              commit("patientLoaded", this.state.patient);
              this.dispatch("getPatient", {
                patientExternalId: this.state.patient.external_id
              });
              break;
            case "FAILURE":
              commit("apiError", "Failed to fetch");
              break;
            default:
              setTimeout(() => {
                this.dispatch("pollTask", taskId);
              }, 3000);
          }
          return resp.data;
        })
        .catch(() => commit("apiError", "Failed to fetch"));
    },
    getAppointment({ commit }, appointmentId) {
      return api
        .get("/appointments/" + appointmentId)
        .then(resp => {
          const appointment = resp.data || {};

          if (!appointment) {
            return;
          }

          const {
            id,
            external_id,
            check_in_display_status,
            timezone,
            display_timezone,
            service_date_start,
            service_date_start_local,
            location,
            provider,
            check_in,
            check_in_actions
          } = appointment;

          // convert response from /appointment/{appointmentId} to response of /patient-lookup/{patientId}
          //  in order to save it to the store
          const appointmentFromLookup = {
            id: Number(id),
            external_id,
            check_in: {
              kiosk_code: check_in.kiosk_code || null,
              display_issue_blocks: check_in.data
                ? check_in.data.display_issue_blocks
                : [],
              issues_count:
                check_in.data && check_in.data.issue_blocks
                  ? check_in.data.issue_blocks.length
                  : 0,
              insurances:
                check_in.data && check_in.data.insurances
                  ? check_in.data.insurances
                  : [],
              config: check_in.config || {}
            },
            check_in_display_status,
            display_timezone,
            service_date_start,
            service_date_start_local,
            timezone,
            location,
            provider,
            check_in_actions
          };

          commit("updatePatientAppointment", appointmentFromLookup);
          return appointmentFromLookup;
        })
        .catch(() => {
          // there is no error state for fetching appointment right now
          return;
        });
    },
    authSuccess({ commit, dispatch }, user) {
      commit("authSuccess", user);
      const { resetTimer } = startLogoutTimer({
        timeoutPeriodMinutes:
          (user && user.sidebar_logout_timeout_minutes) || null,
        logoutUser: () => dispatch("logout")
      });
      if (resetTimer) {
        commit("setResetTimer", resetTimer);
      }
    },
    resetLogoutTimer({ state }) {
      if (state.resetTimer) {
        state.resetTimer();
      }
    }
    // runResync
  },
  getters: {
    isLoggedIn: state => !!state.user,
    isFeatureEnabled: state => featureName => {
      if (!state.patient && !state.patients) {
        return false;
      }

      const { client, company } = state.patient || state.patients[0] || {};
      const relevantFeatures = (state.features || []).filter(
        feature => feature.name === featureName
      );

      if (!client || !company || relevantFeatures.length === 0) {
        return false;
      }

      for (let i = 0; i < relevantFeatures.length; i++) {
        const doesCompanyMatch = relevantFeatures[i].company === company;
        const doesClientMatch =
          !relevantFeatures[i].client || relevantFeatures[i].client === client;

        if (doesCompanyMatch && doesClientMatch && relevantFeatures[i].active) {
          return true;
        }
      }

      return false;
    },
    getCurrentCompany: state => companyId => {
      return state.companies.find(
        company => company.id === companyId.toString()
      );
    }
  }
});

export default store;

api.interceptors.request.use(config => {
  config.headers.common["X-CSRF-Token"] = "1";

  return config;
});
